<script setup lang="ts">
import { AXIOS_INSTANCE } from '~/api/mutator/customInstance';

const { start, stop, stopAll } = useLoading();
const ready = ref(false);

onMounted(() => {
  // 共通ヘッダーを付与するインターセプターの登録
  AXIOS_INSTANCE.interceptors.request.use((requestConfig) => {
    // APIのメソッドとURLを使用してローディングキーを生成する
    const key = `${requestConfig.method}:${AXIOS_INSTANCE.getUri(
      requestConfig,
    )}`;
    start(key);
    return requestConfig;
  });

  // レスポンス時に共通で実行したい処理のインターセプターの登録
  AXIOS_INSTANCE.interceptors.response.use(
    (response) => {
      // APIのメソッドとURLを使用してローディングキーを生成する
      const key = `${response.config.method}:${AXIOS_INSTANCE.getUri(
        response.config,
      )}`;
      stop(key);
      return response;
    },
    (error) => {
      stopAll();
      return Promise.reject(error);
    },
  );

  ready.value = true;
});
</script>

<template>
  <slot v-if="ready" />
</template>
