export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"hid":"description","name":"description","content":"毎年大人気の英語力測定テストが、今年はさらにパワーアップ！ 中学レベルの英単語力をゲーム感覚で楽みながら測定できるコンテンツに変わります。30秒の間、ランダムに出題される英単語クイズに何問答えられるか挑戦しよう！"},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"og:site_name","property":"og:site_name","content":"英語力測定テスト NHK出版"},{"hid":"og:url","property":"og:url","content":"https://eigoryoku.nhk-book.co.jp/"},{"hid":"og:title","property":"og:title","content":"中学英単語タイムアタック！"},{"hid":"og:site_name","property":"og:site_name","content":"NHK出版"},{"hid":"og:description","property":"og:description","content":"30秒の間、ランダムに出題される、英単語クイズに何問答えられるか挑戦しよう！"},{"hid":"twitter:title","name":"twitter:title","content":"中学英単語タイムアタック！"},{"hid":"twitter:card","name":"twitter:card","content":"summary_large_image"},{"hid":"twitter:site","name":"twitter:site","content":"＠nhkp_gogakucafe"},{"hid":"og:image:width","property":"og:image:width","content":"1200"},{"hid":"og:image:height","property":"og:image:height","content":"630"},{"hid":"twitter:image","name":"twitter:image","content":"https://eigoryoku.nhk-book.co.jp/ogp.png?v1"},{"hid":"og:image","property":"og:image","content":"https://eigoryoku.nhk-book.co.jp/ogp.png?v1"}],"link":[],"style":[],"script":[],"noscript":[],"title":"中学英単語タイムアタック！ | 英語力測定テスト | NHK出版","htmlAttrs":{"lang":"ja","prefix":"og: http://ogp.me/ns#"}}

export const appBaseUrl = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnUrl = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'