<template>
  <div class="h-screen bg-bg_normal">
    <NuxtLink to="/normal">
      <Img
        class="mb-10"
        src="/images/question/title/normal.svg"
        alt="NHK出版ぷれぜんつ 中学英単語タイムアタック！"
      />
    </NuxtLink>

    <Img class="mb-8" src="/images/error/404.svg" alt="Page not found." />
    <p class="mx-4 mb-8 text-center text-[20px]">
      ご利用いただきありがとうございます。<br />
      申し訳ございませんが、アクセスいただいたページが見つかりません。<br />
    </p>

    <NuxtLink to="/normal/">
      <Img src="/images/error/to-top.svg" alt=" " />
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
useHead(() => ({
  title: 'ページが見つかりません | 英語力測定テスト | NHK出版',
  meta: [
    {
      name: 'robots',
      content: 'noindex,nofollow',
    },
  ],
}));
</script>
