import Axios, { type AxiosRequestConfig } from 'axios';

export const AXIOS_INSTANCE = Axios.create();

/**
 * @see https://orval.dev/guides/custom-axios
 */
export const customInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<T> => {
  const runtimeConfig = useRuntimeConfig();
  // Basic Auth
  const username = runtimeConfig.public.basicAuthUsername;
  const password = runtimeConfig.public.basicAuthPassword;
  const authHeader = {
    Authorization: `Basic ${btoa(`${username}:${password}`)}`,
  };

  const source = Axios.CancelToken.source();

  const promise = AXIOS_INSTANCE({
    baseURL:
      runtimeConfig.public.appEnv === 'local'
        ? runtimeConfig.public.apiBaseUrl
        : runtimeConfig.public.apiBaseUrl + '/api/',
    ...config,
    headers: {
      ...authHeader,
    },
    ...options,
    cancelToken: source.token,
  }).then(({ data }) => data);

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};
