<template>
  <div
    style="
      position: fixed;
      inset: 0;
      z-index: 2000;
      background-color: rgba(48, 48, 48, 0.7);
    "
  ></div>
</template>
