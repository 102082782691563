import { v4 as uuidv4 } from 'uuid';

export const useUid = () => {
  const generateUid = () => {
    const uuid = uuidv4();
    return uuid;
  };
  return {
    generateUid,
  };
};
