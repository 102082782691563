<template>
  <NuxtErrorBoundary @error="onError">
    <VLoading v-if="isLoading" />
    <VApiClientInitializer>
      <NuxtLayout>
        <NuxtPage />
        <ErrorModal />
      </NuxtLayout>
    </VApiClientInitializer>
  </NuxtErrorBoundary>
</template>

<script setup lang="ts">
// import vConsole from 'vconsole';
import ErrorModal from '~/components/ErrorModal.vue';
import { useLocalstorage } from '~/composables/useLocalstorage';
import { useUid } from '~/composables/useUid';

const { $sentryCaptureException } = useNuxtApp();
const { isLoading } = useLoading();
const runtimeConfig = useRuntimeConfig();
const { generateUid } = useUid();
const { STORAGE_KEY, getStorage, setStorage } = useLocalstorage();

const onError = (error: any) => {
  console.error(error);
  $sentryCaptureException(error);
  showError(error);
};

onMounted(() => {
  // 初回アクセス時にユーザーIDを発行する
  if (!getStorage(STORAGE_KEY.USER_ID)) {
    const uuid = generateUid();
    setStorage(STORAGE_KEY.USER_ID, uuid);
  }

  // debugger
  // if (runtimeConfig.public.appEnv !== 'prd') {
  //   // eslint-disable-next-line new-cap
  //   const vconsole = new vConsole();
  // }
});
</script>
