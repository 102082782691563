import { default as indexPMCJkF9I5xMeta } from "/home/circleci/project/src/pages/index.vue?macro=true";
import { default as indexZhCbIOg6gvMeta } from "/home/circleci/project/src/pages/normal/index.vue?macro=true";
import { default as complete4clutCVzfXMeta } from "/home/circleci/project/src/pages/normal/question/complete.vue?macro=true";
import { default as indexv5g5QJRzlAMeta } from "/home/circleci/project/src/pages/normal/question/index.vue?macro=true";
import { default as _91name_93APtoyfK1p6Meta } from "/home/circleci/project/src/pages/normal/share/[score]/[ranking]/[name].vue?macro=true";
import { default as indexzRLydG1BKDMeta } from "/home/circleci/project/src/pages/normal/survey/complete/index.vue?macro=true";
import { default as indexuxA25XnAmOMeta } from "/home/circleci/project/src/pages/normal/survey/index.vue?macro=true";
import { default as indexuobHt1VTHMMeta } from "/home/circleci/project/src/pages/oni/index.vue?macro=true";
import { default as completeAqwEO3ylMuMeta } from "/home/circleci/project/src/pages/oni/question/complete.vue?macro=true";
import { default as indexmLDdYFgE25Meta } from "/home/circleci/project/src/pages/oni/question/index.vue?macro=true";
import { default as _91name_93gU0UYrhjXgMeta } from "/home/circleci/project/src/pages/oni/share/[score]/[ranking]/[name].vue?macro=true";
import { default as indexM4zboMMKo1Meta } from "/home/circleci/project/src/pages/result/normal/index.vue?macro=true";
import { default as index1fHEiv4ZA0Meta } from "/home/circleci/project/src/pages/result/oni/index.vue?macro=true";
export default [
  {
    name: indexPMCJkF9I5xMeta?.name ?? "index",
    path: indexPMCJkF9I5xMeta?.path ?? "/",
    meta: indexPMCJkF9I5xMeta || {},
    alias: indexPMCJkF9I5xMeta?.alias || [],
    redirect: indexPMCJkF9I5xMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexZhCbIOg6gvMeta?.name ?? "normal",
    path: indexZhCbIOg6gvMeta?.path ?? "/normal",
    meta: indexZhCbIOg6gvMeta || {},
    alias: indexZhCbIOg6gvMeta?.alias || [],
    redirect: indexZhCbIOg6gvMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/normal/index.vue").then(m => m.default || m)
  },
  {
    name: complete4clutCVzfXMeta?.name ?? "normal-question-complete",
    path: complete4clutCVzfXMeta?.path ?? "/normal/question/complete",
    meta: complete4clutCVzfXMeta || {},
    alias: complete4clutCVzfXMeta?.alias || [],
    redirect: complete4clutCVzfXMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/normal/question/complete.vue").then(m => m.default || m)
  },
  {
    name: indexv5g5QJRzlAMeta?.name ?? "normal-question",
    path: indexv5g5QJRzlAMeta?.path ?? "/normal/question",
    meta: indexv5g5QJRzlAMeta || {},
    alias: indexv5g5QJRzlAMeta?.alias || [],
    redirect: indexv5g5QJRzlAMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/normal/question/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93APtoyfK1p6Meta?.name ?? "normal-share-score-ranking-name",
    path: _91name_93APtoyfK1p6Meta?.path ?? "/normal/share/:score()/:ranking()/:name()",
    meta: _91name_93APtoyfK1p6Meta || {},
    alias: _91name_93APtoyfK1p6Meta?.alias || [],
    redirect: _91name_93APtoyfK1p6Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/normal/share/[score]/[ranking]/[name].vue").then(m => m.default || m)
  },
  {
    name: indexzRLydG1BKDMeta?.name ?? "normal-survey-complete",
    path: indexzRLydG1BKDMeta?.path ?? "/normal/survey/complete",
    meta: indexzRLydG1BKDMeta || {},
    alias: indexzRLydG1BKDMeta?.alias || [],
    redirect: indexzRLydG1BKDMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/normal/survey/complete/index.vue").then(m => m.default || m)
  },
  {
    name: indexuxA25XnAmOMeta?.name ?? "normal-survey",
    path: indexuxA25XnAmOMeta?.path ?? "/normal/survey",
    meta: indexuxA25XnAmOMeta || {},
    alias: indexuxA25XnAmOMeta?.alias || [],
    redirect: indexuxA25XnAmOMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/normal/survey/index.vue").then(m => m.default || m)
  },
  {
    name: indexuobHt1VTHMMeta?.name ?? "oni",
    path: indexuobHt1VTHMMeta?.path ?? "/oni",
    meta: indexuobHt1VTHMMeta || {},
    alias: indexuobHt1VTHMMeta?.alias || [],
    redirect: indexuobHt1VTHMMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/oni/index.vue").then(m => m.default || m)
  },
  {
    name: completeAqwEO3ylMuMeta?.name ?? "oni-question-complete",
    path: completeAqwEO3ylMuMeta?.path ?? "/oni/question/complete",
    meta: completeAqwEO3ylMuMeta || {},
    alias: completeAqwEO3ylMuMeta?.alias || [],
    redirect: completeAqwEO3ylMuMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/oni/question/complete.vue").then(m => m.default || m)
  },
  {
    name: indexmLDdYFgE25Meta?.name ?? "oni-question",
    path: indexmLDdYFgE25Meta?.path ?? "/oni/question",
    meta: indexmLDdYFgE25Meta || {},
    alias: indexmLDdYFgE25Meta?.alias || [],
    redirect: indexmLDdYFgE25Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/oni/question/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93gU0UYrhjXgMeta?.name ?? "oni-share-score-ranking-name",
    path: _91name_93gU0UYrhjXgMeta?.path ?? "/oni/share/:score()/:ranking()/:name()",
    meta: _91name_93gU0UYrhjXgMeta || {},
    alias: _91name_93gU0UYrhjXgMeta?.alias || [],
    redirect: _91name_93gU0UYrhjXgMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/oni/share/[score]/[ranking]/[name].vue").then(m => m.default || m)
  },
  {
    name: indexM4zboMMKo1Meta?.name ?? "result-normal",
    path: indexM4zboMMKo1Meta?.path ?? "/result/normal",
    meta: indexM4zboMMKo1Meta || {},
    alias: indexM4zboMMKo1Meta?.alias || [],
    redirect: indexM4zboMMKo1Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/result/normal/index.vue").then(m => m.default || m)
  },
  {
    name: index1fHEiv4ZA0Meta?.name ?? "result-oni",
    path: index1fHEiv4ZA0Meta?.path ?? "/result/oni",
    meta: index1fHEiv4ZA0Meta || {},
    alias: index1fHEiv4ZA0Meta?.alias || [],
    redirect: index1fHEiv4ZA0Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/result/oni/index.vue").then(m => m.default || m)
  }
]