/**
 * 複数のローディング状態を管理する
 */
export const useLoading = () => {
  const state = useState('loading', () => new Set<string>());

  /**
   * ローディングの開始
   */
  const start = (key: string) => state.value.add(key);

  /**
   * ローディングの終了
   * NOTE: start(key) 時と同じ key を指定する必要がある
   */
  const stop = (key: string) => state.value.delete(key);

  /**
   * すべてのローディングを終了
   */
  const stopAll = () => state.value.clear();

  // key が存在する場合はローディング中とみなす
  const isLoading = computed(() => state.value.size > 0);

  return {
    state,
    isLoading,
    start,
    stop,
    stopAll,
  };
};
