export const useLocalstorage = () => {
  const STORAGE_KEY = {
    USER_ID: 'nhk-user-id',
    USER_NAME: 'nhk-user-name',
    // アンケート回答済みフラグ
    ANSWERED_QUESTIONNAIRE: 'nhk-answered-questionnaire',
    // 鬼モード解放フラグ
    RELEASED: 'nhk-released',
    // score
    SCORE_NORMAL: 'nhk-score-normal',
    SCORE_ONI: 'nhk-score-oni',
    // 過去の最高得点
    OLD_BEST_SCORE_NORMAL: 'nhk-old-best-score-normal',
    OLD_BEST_SCORE_ONI: 'nhk-old-best-score-oni',
    // 最高得点だったか
    IS_BEST_SCORE_NORMAL: 'nhk-is-best-score-normal',
    IS_BEST_SCORE_ONI: 'nhk-is-best-score-oni',
  } as const;
  type StorageKey = (typeof STORAGE_KEY)[keyof typeof STORAGE_KEY];

  const getStorage = (key: StorageKey) => {
    const data = localStorage.getItem(key);
    if (!data) return;
    return JSON.parse(data);
  };

  const setStorage = (key: StorageKey, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  return {
    STORAGE_KEY,
    getStorage,
    setStorage,
  };
};
