<script setup lang="ts">
import {
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
} from 'radix-vue';
import Img from '~/components/Img.vue';
import SmallButton from '~/components/SmallButton.vue';

const errorModal = useErrorModalStore();
</script>

<template>
  <DialogRoot :open="errorModal.isOpen">
    <DialogPortal>
      <DialogOverlay
        class="data-[state=open]:animate-overlayShow fixed inset-0 z-[2000] bg-[#303030]/[.7]"
        @click="errorModal.close"
      />
      <DialogContent @escape-key-down="errorModal.close">
        <div
          class="data-[state=open]:animate-contentShow fixed left-[50%] top-[50%] z-[2001] max-h-[85vh] w-full max-w-[336px] translate-x-[-50%] translate-y-[-50%] focus:outline-none"
        >
          <div class="modal">
            <div class="modal_frame_top"></div>
            <div class="modal__body">
              <div class="flex justify-center">
                <Img
                  alt="通信エラー"
                  :width="126"
                  src="/images/modal/error_modal_header_text.svg"
                />
              </div>
              <div class="mt-4">
                <Img
                  alt="むぅ・・通信環境を見直したらどうだ？"
                  src="/images/modal/error_modal_cat_comment.png"
                  class="image-rendering-pixelated"
                  :width="288"
                />
              </div>
              <p class="mt-4">
                通信エラーが発生しました。<br />閉じるをタップ後、再度操作を行ってください。
              </p>
              <div class="mt-4 grid grid-cols-[auto_1fr] gap-1 text-xs">
                <div>※</div>
                <p>
                  ブラウザの更新を行うと情報がリセットされる場合があります。
                </p>
              </div>
              <div class="mt-4 flex justify-center">
                <SmallButton variant="close" @click="errorModal.close" />
              </div>
              <button class="absolute right-6 top-6" @click="errorModal.close">
                <Img alt="" src="/images/modal/modal_close_button.svg" />
              </button>
            </div>
            <div class="modal_frame_bottom"></div>
          </div>
        </div>
      </DialogContent>
    </DialogPortal>
  </DialogRoot>
</template>

<style scoped>
.modal {
  position: relative;
}

.modal_frame_top,
.modal_frame_bottom {
  width: calc(100% - 16px);
  height: 24px;
  background-color: #f8f8f1;
  transform: translateX(8px);
}

.modal_frame_top {
  margin-bottom: -1px;
}
.modal_frame_bottom {
  margin-top: -1px;
}

.modal_frame_top::before,
.modal_frame_top::after,
.modal_frame_bottom::before,
.modal_frame_bottom::after {
  position: absolute;
  width: 24px;
  height: 24px;
  content: '';
  background-image: url(/images/modal/modal_edge.svg);
  background-size: cover;
}
.modal_frame_top::before {
  bottom: 0;
  left: -8px;
}
.modal_frame_top::after {
  top: 0;
  right: -8px;
  transform: scale(-1, 1);
}
.modal_frame_bottom::before {
  bottom: 0;
  left: -8px;
  transform: scale(1, -1);
}
.modal_frame_bottom::after {
  right: -8px;
  bottom: 0;
  transform: scale(-1, -1);
}

.modal__body {
  padding: 1px 24px;
  background-color: #f8f8f1;
}
</style>
