<template>
  <img
    :src="src"
    :alt="alt"
    :style="styles"
    :decoding="decoding"
    :class="className"
    @contextmenu.prevent
  />
</template>

<script setup lang="ts">
import { twMerge } from 'tailwind-merge';
interface Props {
  src: HTMLImageElement['src'];
  alt?: HTMLImageElement['alt'];
  class?: string;
  // 条件によって読み込む画像を変えたい時のsrcset設定（RetinaDisplay対応など）
  width?: HTMLImageElement['width'];
  height?: HTMLImageElement['height'];
  // 遅延デコード（画像の表示を待つか）
  decoding?: 'auto' | 'async' | 'sync';
  // 画面に表示されていない画像を先に読み込むかどうか
  loading?: 'eager' | 'lazy';
}
const props = withDefaults(defineProps<Props>(), {
  alt: '',
  width: 0,
  height: 0,
  class: '',
  replaceType: 'landscape',
  // 遅延デコード（画像の表示を待つか）
  decoding: 'async',
  // 画面に表示されていない画像を先に読み込むかどうか
  loading: 'eager',
});

const styles = computed(() => ({
  ...(props.width ? { width: props.width + 'px' } : { width: 'auto' }),
  ...(props.height ? { height: props.height + 'px' } : { height: 'auto' }),
}));

const className = computed(() =>
  twMerge('m-auto object-contain object-center', props.class),
);
</script>
