import { defineNuxtPlugin, useRuntimeConfig } from '#app';
import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  Sentry.init({
    enabled: true, // config.public.sentryEnabled,
    app: nuxtApp.vueApp,
    autoSessionTracking: true,
    // TODO: 環境変数問題改善後に修正 以下本番
    dsn: 'https://9f860f6e6edfce829115a6a66ddf1145@o4506194680020992.ingest.sentry.io/4506387845283840', // config.public.sentryDsn,
    environment: 'prd', // config.public.appEnv,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(
          nuxtApp.$router as any,
        ),
      }),
      new Sentry.Replay(),
    ],
    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    tracesSampleRate: 0.001, // config.public.sentryTracesSampleRate,
    replaysSessionSampleRate: 0.001, // config.public.sentryReplaysSessionSampleRate,
    replaysOnErrorSampleRate: 0.001, // config.public.sentryReplaysOnErrorSampleRate,
  });

  return {
    provide: {
      sentrySetUser: Sentry.setUser,
      sentryCaptureException: Sentry.captureException,
    },
  };
});
